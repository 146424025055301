import axios, { AxiosRequestConfig } from "axios";


export const vtaimedR20 = async (token: string): Promise<any> => {
   
    const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/vtaimedr20`,
        data:{
            token: token
        }
    }
    const url = (await axios.request(config)).data.data;
    return url;
}

export const obtimedinterR20 = async(data: any, token: string): Promise<any> =>{

    data.token = token;

    const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/obtintermulr20`,
        data
    }

    return (await axios.request(config)).data;



}