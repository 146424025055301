import React, { Component } from "react";
import { parse } from "querystring";
import { verifyJWT, obtimedinterR20 } from "../endpoints";
import { GridDataTable, GridFooterTable, GridTable } from "./interfaces";
import { Bono } from "../component/bono";


export interface FracasoProps {

}

export interface FracasoState {
    query: any;
    glosaError: string;
    codeError: string;
    numTransaction: string;
    noBonificado: GridTable
    loading: boolean;

}

export class Fracaso extends Component<FracasoProps, FracasoState>{

    constructor(props: FracasoProps) {
        super(props);
        this.state = {
            query: window.location.search ? parse(window.location.search.substring(1)) : undefined,
            glosaError: "",
            codeError: "",
            numTransaction: "",
            noBonificado: {},
            loading: true,

        }
    }

    prepareDataNoBonificado(data: any): GridTable {

        //const bonificado: Array<GridDataTable> = [];
        const bonificado: GridDataTable[] = data.no_bonificado.map((b: any) => {
            const result: GridDataTable = {
                "Convenio": b.rut_convenio,
                "Cod. Prestación": b.cod_prestacion,
                "Cod. Item": b.cod_item,
                "Descripción": b.prestacion_desc,
                "Cantidad": b.cantidad,
                "R.H": b.recargo_hora,
                //"Monto Sap": b.monto_particular_sap,
                "Monto": b.monto_prestacion_imed,
                "Bonificación": b.monto_bonificado_imed,
                "Seg. Comp.": b.otras_bon_imed,
                "Total a Pagar": b.monto_particular_sap
            };
            return result
        });

        const totales: GridFooterTable = {
           // montoTotalSap: data.total_no_bonificado[0].total_prestacion_sap,
            montoTotalImed: data.total_no_bonificado[0].total_prestacion_imed,
            montoTotalBonificado: data.total_no_bonificado[0].total_bonificado_imed,
            montoTotalSegComp: data.total_no_bonificado[0].total_otras_bon_imed,
            montoTotal: data.total_no_bonificado[0].total_prestacion_sap,
            montoTotalExedente: data.total_no_bonificado[0].total_excedentes_imed
        }

        return {
            grid: bonificado,
            totales: totales
        }
    }

    async componentDidMount() {
        const data = await verifyJWT(this.state.query.token);
        data.contexto = "fracaso";
        obtimedinterR20(data, this.state.query.token).then(resp => {
            this.setState({
                codeError: data.CodError,
                glosaError: data.GlosaError,
                numTransaction: data.NumTransac,
                noBonificado: this.prepareDataNoBonificado(resp),
                loading: false
            });

        }).catch((e) => this.setState({ loading: false, codeError: data.CodError, glosaError: data.GlosaError, numTransaction: data.NumTransac }));

    }

    render(): JSX.Element {
        return (<>
            <div className="container">
                {this.state.loading &&
                    <div className="shadow d-flex justify-content-center align-items-center " style={{ height: "500px" }}>

                        <div className="row">
                            <div className="col-12 ">
                                <div className="spinner-grow text-danger" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {!this.state.loading &&
                    <>
                        

                        <div className="mb-3">
                            <Bono
                                title={{
                                    title: "Prestaciones No Bonificadas",
                                    className: "title-nobon-color"
                                }}
                                table={{
                                    headClassName: "table-nobono-head"
                                }}
                                data={this.state.noBonificado.grid ? this.state.noBonificado.grid : []}
                                dataFotter={this.state.noBonificado.totales ? this.state.noBonificado.totales : {}}
                            />
                        </div>
                        <div className="border border-3 border-danger rounded rounded-5 mt-3 mb-3">
                            <div className="d-flex justify-content-center">
                                <h4 className="text-danger"><b>{this.state.glosaError}</b></h4>
                            </div>
                            <div className="d-flex justify-content-center mb-2">
                                <small className="text-muted"><b>Transacción: {this.state.numTransaction}</b></small>
                            </div>                           
                        </div>





                    </>
                }


            </div>
        </>)
    }
}