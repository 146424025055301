import React, { Component } from "react";
import { Link } from "react-router-dom";

export interface NavProps {
    imageUrl?: string;
}

export interface NavState {

}

export class Nav extends Component<NavProps, NavState>{

    render(): JSX.Element {
        return (
            <nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="#">
                        <img src={this.props.imageUrl?this.props.imageUrl:"/logo-meds.png"} alt="" className="d-inline-block align-text-top" />
                    </Link>
                </div>
            </nav>
        )
    }

}