import React, { Component } from 'react';
import './App.css';
import { parse } from "querystring";
import { verifyJWT, vtaimedR20 } from "../endpoints";
import { inspect } from 'util';

//import Swal from "sweetalert2";


export interface TRXImed {
  numtransac: string;
  codauditoria: string;
  idSolVta: string;
  coderror: string;
  gloerror: string;
  iat: number;
  exp: number;
}

export interface AppProps {

}

export interface AppState {
  jwt: any;
  idSolVta: string;
  ca: string;
  nt: string;
  gloerror: string;
  coderror: string;
  sending: boolean;
}

export class App extends Component<AppProps, AppState>{

  constructor(props: {}) {
    super(props);

    this.state = {
      jwt: window.location.search ? parse(window.location.search.substring(1)).token : undefined,
      idSolVta: "",
      ca: "",
      nt: "",
      gloerror: "",
      coderror: "-2",
      sending: false
    }
  }

  componentDidMount() {

    if (this.state.jwt) {
      verifyJWT(this.state.jwt).then((trx: TRXImed) => {

        const nt = trx.numtransac;
        const ca = trx.codauditoria;
        const gloerror = trx.gloerror;
        const coderror = trx.coderror;
        const idSolVta = trx.idSolVta;
        this.setState({ ca, nt, gloerror, coderror, idSolVta }, () => {
          if (this.state.coderror === "0") {
            console.log("State antes de redireccionar: "+this.state);
            console.log("URL POST IMED: "+process.env.REACT_APP_IMEDPOST);
            document.forms["myForm" as any].submit();
          }

        });

      }).catch((e: Error) => {
        this.setState({ coderror: "-1", gloerror: "Transacción expirada" });
      })

    }

  }

  render(): JSX.Element {
    return (<>

      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="card mt-2 shadow" style={{ width: "50rem" }}>
            <div className="card-body">
              {this.state.coderror === "-2" &&
                <div className="row">
                  <div className="col-12 d-flex justify-content-center p-3">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                </div>
              }
              {this.state.coderror === "0" &&
                <>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <h2 className="text-muted fw-bold">Redirigiendo a I-MED</h2>
                    </div>
                    <div className="col-12 d-flex justify-content-center p-3">
                      <div className="spinner-grow text-success" role="status">
                        <span className="visually-hidden"></span>
                      </div>
                    </div>
                  </div>
                </>
              }
              {(this.state.coderror === "-1" || this.state.coderror === "1") && this.state.gloerror !== "Cajero no ha iniciado sesion" &&
                <>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <h2 className="text-muted fw-bold">{this.state.gloerror}</h2>
                    </div>
                  </div>
                </>
              }
              {this.state.coderror !== "0" && this.state.gloerror === "Cajero no ha iniciado sesion" &&
                <>
                  {!this.state.sending &&
                    <h2 className="text-danger d-flex justify-content-center">{this.state.gloerror}</h2>
                  }
                  {this.state.sending &&
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center p-3">
                        <div className="spinner-grow text-warning" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="d-flex justify-content-center">
                    <div className="mt-2" style={{ width: "18rem" }}>
                      <div className="row">
                        <div className="col d-flex justify-content-center">
                          <button className="btn btn-success" onClick={() => {

                            //window.open("https://interfaz4pre.bonoelectronico.cl", '_blank')?.focus();
                            window.open(process.env.REACT_APP_LOGINIMED, '_blank')?.focus();
                            
                          }}
                            disabled={this.state.sending}
                          >Autenticar</button>
                        </div>
                        <div className="col d-flex justify-content-center">
                          <button className="btn btn-primary" onClick={() => {
                            this.setState({ sending: true }, () => {
                              vtaimedR20(this.state.jwt).then(resp => {
                                console.log(inspect(resp));
                                this.setState({ sending: false }, () => {
                                  window.location = resp;
                                });
                              }).catch((e) => {
                                this.setState({ sending: false })
                              })
                            });

                          }}
                            disabled={this.state.sending}
                          >
                            {this.state.sending &&
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" ></span>
                            }
                            Bonificar
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/*
        <form action="https://interfaz4pre.bonoelectronico.cl/login.php" method="post" id="myForm">
          <input type="hidden" name="NumTransac" value={this.state.nt} />
          <input type="hidden" name="NroAuditoria" value={this.state.ca} />
        </form>

      */
        <form action="https://interfaz4.bonoelectronico.cl/login.php" method="post" id="myForm">
          <input type="hidden" name="NumTransac" value={this.state.nt} />
          <input type="hidden" name="NroAuditoria" value={this.state.ca} />
        </form>
      }
    </>)
  }

}
