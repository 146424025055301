import React, { Component } from "react";
import { numberToCLP } from "./util";
import { v4 } from "uuid";


export interface BonoProps {
    title: {
        title: string;
        className?: string;
    };

    table?: {
        className?: string,
        headClassName?: string,
        bodyClassName?: string
    };

    data: Array<any>;
    dataFotter: any;

    renderHeader?: (attrs: Array<string>) => JSX.Element;
    renderBody?: (rows: Array<any>, attrs: Array<string>) => JSX.Element;
    renderFooter?: (rows: Array<any>, attrs: Array<string>) => JSX.Element;

}

export interface BonoState {

    data: Array<any>;
    attrs: Array<string>;
    dataFooter: any

}

export class Bono extends Component<BonoProps, BonoState>{

    constructor(props: BonoProps) {
        super(props);
        this.state = {
            data: [],
            attrs: [],
            dataFooter: {}
        }
    }

    async componentDidMount() {

        if (this.props.data.length > 0) {
            const attrs = Object.keys(this.props.data[0]);
            this.setState({ data: this.props.data, attrs, dataFooter: this.props.dataFotter });
        }

    }

    convertData(attr: string, value: string): string {
        switch (attr) {
            case 'Monto':
            case 'Bonificación':
            case 'Seg. Comp.':
            case 'Total a Pagar':
            case 'Monto Sap':
                return numberToCLP(parseInt(value));
            default:
                return value;
        }
    }

    calculateSubTotal(data: any):number{

        const subTotal = data["montoTotalImed"]-data["montoTotalBonificado"]-data["montoTotalSegComp"];
        return subTotal;
    }

    renderHeader(attrs: Array<string>): JSX.Element {
        return <tr>{attrs.map(attr => <th key={v4()}>{attr}</th>)}</tr>
    }

    renderBody(rows: Array<any>, attrs: Array<string>): JSX.Element {
        return <>
            {rows.map(row => {
                return (<tr key={v4()}>
                    {attrs.map(attr => {
                        return (
                            <td key={v4()}>{this.convertData(attr, row[attr])}</td>
                        )
                    })
                    }
                </tr>)
            })
            }
        </>
    }

    renderFooter(rows: Array<any>, attrs: Array<string>, data?: any): JSX.Element {

        if (data) {
            const keys = Object.keys(data);
            const colSpan = attrs.length - keys.length;
            return (
                <>
                    <tr>
                        <td colSpan={colSpan + 1}>
                            <div className="d-flex justify-content-end">
                                <b>Sub Total:</b>
                            </div>
                        </td>
                        {/*
                            keys.filter(key=>key !== "montoTotalExedente").map(key => {
                            return (<td key={v4()}><b key={v4()}>{typeof data[key] === "number"? numberToCLP(data[key]) : data[key]}</b></td>)
                        })*/}
                        <td><b>{numberToCLP(data["montoTotalImed"])}</b></td>
                        <td><b>{numberToCLP(data["montoTotalBonificado"])}</b></td>
                        <td><b>{numberToCLP(data["montoTotalSegComp"])}</b></td>
                        <td><b>{numberToCLP(this.calculateSubTotal(data))}</b></td>
                    </tr>
                    <tr>
                        <td colSpan={colSpan+1}>
                            <div className="d-flex justify-content-end">
                                <b>Excedentes:</b>
                            </div>
                        </td>                        
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>({numberToCLP(data["montoTotalExedente"])})</td>
                        
                        
                    </tr>
                    <tr>
                        <td colSpan={colSpan+1}>
                            <div className="d-flex justify-content-end">
                                <b>Total:</b>
                            </div>
                        </td>                        
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>{numberToCLP(data["montoTotal"])}</b></td>
                        
                    </tr>
                </>
            );
        }
        else {
            const colSpan = attrs.length;
            return <tr><td colSpan={colSpan}></td></tr>
        }

    }

    render(): JSX.Element {
        return (
            <div>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2 className={this.props.title.className ? this.props.title.className : "title-color"}>{this.props.title.title}</h2>
                    </div>
                </div>
                <div className="table-responsive mt-2">
                    <table className={`${this.props.table && this.props.table.className ? this.props.table.className : "table table-hover"}`}>
                        <thead className={`${this.props.table && this.props.table.headClassName ? this.props.table.headClassName : "table-bono-head"}`}>
                            {this.props.renderHeader ? this.props.renderHeader : this.renderHeader(this.state.attrs)}
                        </thead>
                        <tbody>
                            {this.props.renderBody ? this.props.renderBody : this.renderBody(this.state.data, this.state.attrs)}

                        </tbody>
                        <tfoot>
                            {this.props.renderFooter ? this.props.renderFooter : this.renderFooter(this.state.data, this.state.attrs, this.state.dataFooter)}
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    }
}