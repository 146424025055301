import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './index.css';
import { App } from './component/App';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Nav } from "./component/Nav";
import { Exito, Fracaso } from "./pages"

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Nav />
      <Switch>
        <Route path="/" exact>
          <App />
        </Route>
        <Route path="/exito" exact>
          <Exito />
        </Route>
        <Route path="/fracaso" exact>
          <Fracaso />
        </Route>
      </Switch>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);
