import React, { Component } from "react";
import { verifyJWT, obtimedinterR20 } from "../endpoints";
import { parse } from "querystring";
import { Bono } from "../component/bono";
import {GridDataTable, GridFooterTable, GridTable} from "./interfaces";
import {v4} from "uuid"

export interface ExitoProps {

}

export interface ExitoState {
    query: any;
    bonificado: GridTable,
    noBonificado: GridTable

    loadingBono: boolean;
}

export class Exito extends Component<ExitoProps, ExitoState>{

    constructor(props: ExitoProps) {
        super(props);
        this.state = {
            query: window.location.search ? parse(window.location.search.substring(1)) : undefined,
            loadingBono: true,
            bonificado: {},
            noBonificado: {}
        }
    }


    prepareDataBonificado(data: any): GridTable {

        let bonificado: GridDataTable[] = []
        if(data && Array.isArray(data.bonificado)){
            bonificado = data.bonificado.map((b: any) => {
                const result: GridDataTable = {
                    "Convenio": b.rut_convenio,
                    "Cod. Prestación": b.cod_prestacion,
                    "Cod. Item": b.cod_item,
                    "Descripción": b.prestacion_desc,
                    "Cantidad": b.cantidad,
                    "R.H": b.recargo_hora,
                    //"Monto Sap": b.monto_particular_sap,
                    "Monto": b.monto_prestacion_imed,
                    "Bonificación": b.monto_bonificado_imed,
                    "Seg. Comp.": b.otras_bon_imed,
                    "Total a Pagar": b.monto_copago_imed
                };
                return result
            });
        }
        

        let totales: GridFooterTable = {
            montoTotal: 0,
            montoTotalBonificado: 0,
            //montoTotalSap: 0,
            montoTotalSegComp: 0,
            montoTotalImed: 0,
            montoTotalExedente: 0
        }; 

        if(data && data.bonificado && Array.isArray(data.bonificado) && data.bonificado.length > 0 ){
            totales = {
                //montoTotalSap: data.total_bonificado[0].total_prestacion_sap,
                montoTotalImed: data.total_bonificado[0].total_prestacion_imed,
                montoTotalBonificado: data.total_bonificado[0].total_bonificado_imed,
                montoTotalSegComp: data.total_bonificado[0].total_otras_bon_imed,
                montoTotal: data.total_bonificado[0].total_copago_imed,
                montoTotalExedente: data.total_bonificado[0].total_excedentes_imed
            }
        }

       

        return {
            grid: bonificado,
            totales: totales
        }
    }

    prepareDataNoBonificado(data: any): GridTable {

        let noBonificado: GridDataTable[] = []
        if(data && Array.isArray(data.no_bonificado)){
            noBonificado = data.no_bonificado.map((b: any) => {
                const result: GridDataTable = {
                    "Convenio": b.rut_convenio,
                    "Cod. Prestación": b.cod_prestacion,
                    "Cod. Item": b.cod_item,
                    "Descripción": b.prestacion_desc,
                    "Cantidad": b.cantidad,
                    "R.H": b.recargo_hora,
                    //"Monto Sap": b.monto_particular_sap,
                    "Monto": b.monto_prestacion_imed,
                    "Bonificación": b.monto_bonificado_imed,
                    "Seg. Comp.": b.otras_bon_imed,
                    "Total a Pagar": b.monto_copago_imed
                };
                return result
            });
        }

        let totales: GridFooterTable = {
            montoTotal: 0,
            montoTotalBonificado: 0,
            //montoTotalSap: 0,
            montoTotalSegComp: 0,
            montoTotalImed: 0,
            montoTotalExedente: 0
        }; 

        if(data.total_no_bonificado && Array.isArray(data.total_no_bonificado) && data.total_no_bonificado.length > 0 ){
            totales = {

                montoTotalImed: 0,
                montoTotalBonificado: 0,
                montoTotalSegComp: 0,
                montoTotal: data.total_no_bonificado[0].total_prestacion_sap,
                montoTotalExedente: 0
                
                /*
                montoTotalImed: data.total_no_bonificado[0].total_prestacion_imed,
                montoTotalBonificado: data.total_no_bonificado[0].total_bonificado_imed,
                montoTotalSegComp: data.total_no_bonificado[0].total_otras_bon_imed,
                montoTotal: data.total_no_bonificado[0].total_prestacion_sap,
                montoTotalExedente: data.total_no_bonificado[0].total_excedentes_imed
                */
            }
        }

        return {
            grid: noBonificado,
            totales: totales
        }
    }

    async componentDidMount() {

        const data = await verifyJWT(this.state.query.token);
        data.contexto = "exito";

        obtimedinterR20(data, this.state.query.token).then(resp => {

            this.setState({
                bonificado: this.prepareDataBonificado(resp),
                noBonificado: this.prepareDataNoBonificado(resp),
                loadingBono: false
            });
        }).catch((e) => this.setState({ loadingBono: false }, ()=>{
            console.error(e.message);
        }));


    }

    render(): JSX.Element {
        return (<>
            <div className="container">
                <div className="d-flex justify-content-center shadow">
                    {this.state.loadingBono &&
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center p-3">
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="visually-hidden"></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {!this.state.loadingBono &&
                    <>
                    {this.state.bonificado.grid && this.state.bonificado.grid.length > 0 &&
                        <Bono
                            title={{
                                title: "Prestaciones Bonificadas"
                            }}
                            data={this.state.bonificado.grid ? this.state.bonificado.grid : []}
                            dataFotter={this.state.bonificado.totales ? this.state.bonificado.totales : {}}
                            key={v4()}
                        />
                    }
                        {this.state.noBonificado.grid && this.state.noBonificado.grid.length > 0 &&
                            <Bono
                                title={{
                                    title: "Prestaciones No Bonificadas",
                                    className: "title-nobon-color"
                                }}
                                table={{
                                    headClassName: "table-nobono-head"
                                }}
                                data={this.state.noBonificado.grid ? this.state.noBonificado.grid : []}
                                dataFotter={this.state.noBonificado.totales ? this.state.noBonificado.totales : {}}
                                key={v4()}
                            />
                        }

                    </>
                }

            </div>
        </>)
    }
}